import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { DataContext } from '../../../reducer/GlobalState'
import { CalenderDropdown } from './custom/CalenderDropdown';

type Props = {
  color: string
  title: string
  subtitle: string
  icon: string
  status?: number
  url: string
  className: string
  money?: boolean
}
//this month 
const s_month = new Date()
const e_month = new Date()
const this_month_end = new Date(e_month.getFullYear(), e_month.getMonth() + 1, 0)
const this_month_start = new Date(s_month.setDate(1))

export const IncomeDash: React.FC<Props> = ({
  color,
  title,
  subtitle,
  icon,
  status,
  url,
  className,
  money,
}) => {
  const { dispatch } = useContext(DataContext)
  const [loading, setLoading] = useState(false)

  const [formInput, setFormInput] = useState({
    DateStart: this_month_start.toLocaleDateString('pt-br').split('/').reverse().join('-'),
    DateEnd: '',
  })
  const selectionRange = {
    startDate: this_month_start,
    endDate: this_month_end,
    key: 'data',
  }
  const [dateRange, setDateRange] = useState(selectionRange)

  const [counts, setCounts] = useState([])
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    setLoading(true)
    let run = false
    axios
      .post(`${url}${status ? '/' + status : ''}`, formInput)
      .then((resp) => {
        if (!run) {
          setCounts(resp.data.Total_Amount || 0)
          setLoading(false)
        }
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
    const delayDebounceFn = setTimeout(() => {
      setRefresh(x => x + 1)
    }, 300000)
    return () => {
      run = true
      clearTimeout(delayDebounceFn)
    }
  }, [dispatch, formInput, status, url, refresh])

  return (
    <div className={`${className} mb-5 border-${color} border-bottom border-5`}>
      <div className='card-header align-items-start border-0 mt-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='text-muted fw-bold fs-7'>{subtitle}</span>
          {loading ?
            <span className={`spinner-border text-${color}`}></span>
            :
            <span className={`fw-bolder mb-2 fs-3 text-${color}`}>
              {formInput.DateStart ? counts : 0} {money && '$'}
            </span>
          }
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className={`btn btn-sm btn-icon btn-color-${color} btn-active-light-${color} border-0 me-n3`}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-1' />
          </button>
          <CalenderDropdown formInput={formInput} setFormInput={setFormInput} dateRange={dateRange} setDateRange={setDateRange} color={color} />
        </div>
      </div>

    </div>
  )
}

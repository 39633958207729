import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import SubscriptionsItem from './components/SubscriptionsItem'
import { DataContext } from '../../../../reducer/GlobalState'
import { MyPagination } from '../../../../../components/MyPagination'
import clsx from 'clsx'
import { Col, Form, Modal, Row } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../../_metronic/helpers'
import NekworkModal from './components/NekworkModal'


const num_page = 10

function Subscriptions({ className }) {

  const { dispatch } = useContext(DataContext)
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [Profile, setProfile] = useState([])
  const [subscriptions, setSubscriptions] = useState([])

  const [showModal, setShowModal] = useState(false)
  const handleModelClose = () => {
    setShowModal(!showModal)
    setFormInput(initialStateForm)
    setRouterList([])
    setVlanLsit([])
    setErrors({})
    setNetworks([])
  }
  const [showModalNetwork, setShowModalNetwork] = useState(false)
  const handleModelCloseNekwork = () => {
    setShowModalNetwork(!showModalNetwork)
    setNetworks([])
  }


  const [activate, setActivate] = useState(1)
  const [page, setPage] = useState(1)
  const [showId, setShowId] = useState(0)

  const [popList, setPopList] = useState([])
  const [routerList, setRouterList] = useState([])
  const [connectTypeList, setConnectTypeList] = useState([])

  const [vlanLsit, setVlanLsit] = useState([])

  const [errors, setErrors] = useState({})
  const [isSubmiting, setIsSubmiting] = useState(false)

  const initialStateForm = {
    subscription_id: '',
    pop_id: '',
    connection_type: 'vlan',
    subnet: '',
    subnet_id: '',
    ip: '',
    device_id: '',
    gateway: '',
    addressing: 'static',
    vlan_id: '',
  }
  const [formInput, setFormInput] = useState(initialStateForm)

  const dataFetch = async () => {
    setLoading(true)
    await axios
      .get(`/subscription/service-status-subscription-count`)
      .then((resp) => {
        if (resp.data) {
          setProfile(resp.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
  }

  const [networks, setNetworks] = useState([])
  const [subId, setSubId] = useState(0)
  const handleAddNetwork = (i) => {
    axios
      .get(`/network/show-network/${i}`)
      .then((resp) => {
        if (resp.data.data !== '') {
          setNetworks(resp.data.data)
        }
      })

      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
    setSubId(i)
    groupFetch()
    setShowModal(true)
    setFormInput({ ...formInput, subscription_id: i })
  }


  const SubscriptionFetch = async (i) => {
    setActivate(i)
    setPage(1)
    setShowId(0)
    setIsLoading(true)
    await axios
      .get(`/subscription/subscription-service-status-list/${i}`)
      .then((resp) => {
        if (resp.data) {
          setIsLoading(false)
          setSubscriptions(resp.data.data)
        }
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
  }

  const groupFetch = async () => {
    await axios.get('/subscription-list-pop').then((resp) => {
      setPopList(resp.data)
    })

    await axios.get('/device-service/dropdown').then((resp) => {
      setConnectTypeList(resp.data)
    })
  }

  const routerFetch = async (i) => {
    if (i !== '') {
      await axios.get(`/device/dropdown/${i}`).then((resp) => {
        setRouterList(resp.data)
      })
    } else {
      setRouterList([])
    }
  }

  const vlanFetch = async (i) => {
    if (i !== '') {
      await axios.get(`/vlan/get-vlan-dropdown/${i}/${networks.vlan_id}`).then((resp) => {
        setVlanLsit(resp.data)
      })
    } else {
      setVlanLsit([])
    }
  }



  const handleChangeInput = (e) => {
    setErrors({})
    const { name, value } = e.target
    setFormInput({ ...formInput, [name]: value })
  }

  useEffect(() => {
    setLoading(true)
    setIsLoading(true)
    const delayDebounceFn = setTimeout(() => {
      dataFetch()
      SubscriptionFetch(1)
    }, 500)

    return () => clearTimeout(delayDebounceFn)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Nekwork Detail 
  const handleNekworokDetail = (i) => {
    axios
      .get(`/network/show-network/${i}`)
      .then((resp) => {
        if (resp.data.data !== '') {
          setNetworks(resp.data.data)
        }
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
    setSubId(i)
    setShowModalNetwork(true)
  }

  const renderServicetype = () => {
    return subscriptions.map((servicetype, idx) => (
      <SubscriptionsItem
        i={idx}
        showId={showId}
        key={idx}
        servicetype={servicetype}
        num_page={num_page}
        handleAddNetwork={handleAddNetwork}
        handleNekworokDetail={handleNekworokDetail}
      />
    ))
  }

  const handleSubsmit = async () => {
    setIsSubmiting(true)
    await axios
      .post(`network/create-network`, formInput)
      .then((resp) => {
        if (resp.data.status === 'success') {
          SubscriptionFetch(1)
          handleAddNetwork(subId)
          handleModelClose()
          setShowModalNetwork(true)
          dispatch({ type: 'NOTIFY', payload: { success: resp.data.message } })
        } else if (resp.data.status === 'validation') {
          setErrors(resp.data.data)
        } else if (resp.data.message) {
          dispatch({ type: 'NOTIFY', payload: { warning: resp.data.message } })
        } else {
        }
        setIsSubmiting(false)
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
  }


  return (
    <div className={className}>
      {loading ? <p className='text-center'>Loading...</p> :
        <>
          <div className='card pe-5'>
            <div className='row'>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-dark', { active: activate === -1 })} onClick={() => SubscriptionFetch(-1)}>
                  <div>{Profile.totalSubscriptions}</div>
                  <span>Total</span>
                </button>
              </div>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-muted', { active: activate === 0 })} onClick={() => SubscriptionFetch(0)}>
                  <div>{Profile.isCountInactive}</div>
                  <span>Inactive</span>
                </button>
              </div>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-primary', { active: activate === 1 })} onClick={() => SubscriptionFetch(1)}>
                  <div>{Profile.isCountActive}</div>
                  <span>Active</span>
                </button>
              </div>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-warning', { active: activate === 2 })} onClick={() => SubscriptionFetch(2)}>
                  <div>{Profile.isCountLock}</div>
                  <span>Lock</span>
                </button>
              </div>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-danger', { active: activate === 3 })} onClick={() => SubscriptionFetch(3)}>
                  <div>{Profile.isCountClose}</div>
                  <span>Close</span>
                </button>
              </div>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-success', { active: activate === 4 })} onClick={() => SubscriptionFetch(4)}>
                  <div>{Profile.isCountSuspended}</div>
                  <span>Suspended</span>
                </button>
              </div>
            </div>
          </div>

          <div className='card card-body pt-0'>
            <div className='table-responsive'>
              <table id='kt_customers_table' className='table table-sm align-top table-row-dashed fs-6 gy-4'>
                <thead>
                  <tr className='text-start text-gray-400 fw-bolder fs-7 gs-0'>
                    <th className=''>SID</th>
                    <th className=''>Subsription Name</th>
                    <th className=''>Customer Name</th>
                    <th className='min-w-0px'>Tariff Name</th>
                    <th className='min-w-0px'>Status</th>
                    <th className='min-w-0px'>Action</th>
                  </tr>
                </thead>
                <tbody className='fw-bold text-gray-600'>
                  {isLoading ? (
                    <tr>
                      <td colSpan={5}>
                        <p className='text-center'>Loading...</p>
                      </td>
                    </tr>
                  ) : (
                    subscriptions instanceof Array && renderServicetype()
                  )}
                </tbody>
              </table>
              {!isLoading && (
                <div className='d-flex flex-stack flex-wrap'>
                  <div className='fs-6 fw-bold text-gray-700'>
                    Showing {page} to {Math.ceil(subscriptions.length / num_page)} of {subscriptions.length} entries
                  </div>
                  {subscriptions.length > num_page ? (
                    <MyPagination
                      page={page}
                      totalPages={Math.ceil(subscriptions.length / num_page)}
                      handlePagination={(updatePage) => {
                        setPage(updatePage)
                        setShowId((updatePage - 1) * num_page)
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
        </>}

      <Modal show={showModal} scrollable={true} onHide={handleModelClose} size='xl'>
        <Modal.Header>
          <Modal.Title>Network</Modal.Title>
          <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleModelClose}>
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </Modal.Header>
        <Modal.Body>

          <Form.Group as={Row} className='mb-3'>
            <Form.Label column xs='3' className='form-label fw-bold fs-6 text-lg-end'>
              POP
            </Form.Label>
            <Col xs='8' className='fv-row'>
              <Form.Select
                name='pop_id'
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    pop_id: e.target.value,
                    device_id: '',
                    vlan_id: '',
                  })
                  routerFetch(e.target.value)
                  vlanFetch('')
                }}
                value={formInput.pop_id}
                isInvalid={!!errors.pop_id}
                className={!errors.pop_id && `form-select-solid`}
              >
                <option value=''>Please select ...</option>
                {Object.entries(popList).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{errors.pop_id}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column xs='3' className='required form-label fw-bold fs-6 text-lg-end'>
              Device
            </Form.Label>
            <Col xs='8' className='fv-row'>
              <Form.Select
                name='device_id'
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    device_id: e.target.value,
                    vlan_id: '',
                  })
                  vlanFetch(e.target.value)
                }}
                value={formInput.device_id}
                isInvalid={!!errors.device_id}
                className={!errors.device_id && `form-select-solid`}
              >
                <option value=''>Please select ...</option>
                {Object.entries(routerList).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{errors.device_id}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column xs='3' className='required form-label fw-bold fs-6 text-lg-end'>
              VLAN
            </Form.Label>
            <Col xs='8' className='fv-row'>
              <Form.Select
                name='vlan_id'
                onChange={handleChangeInput}
                value={formInput.vlan_id}
                isInvalid={!!errors.vlan_id}
                className={!errors.vlan_id && `form-select-solid`}
              >
                <option value=''>Please select ...</option>
                {Object.entries(vlanLsit).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{errors.vlan_id}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column xs='3' className='required form-label fw-bold fs-6 text-lg-end'>
              Connection Type
            </Form.Label>
            <Col xs='8' className='fv-row'>
              <Form.Select
                name='connection_type'
                onChange={handleChangeInput}
                value={formInput.connection_type}
                isInvalid={!!errors.connection_type}
                className={!errors.connection_type && `form-select-solid`}
              >
                {Object.entries(connectTypeList).map(([key, value]) => (
                  <option key={key} value={value.toLowerCase()}>
                    {value}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{errors.connection_type}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          {formInput.connection_type === 'vlan' ?
            <>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column xs='3' className='required form-label fw-bold fs-6 text-lg-end'>
                  Subnet ID
                </Form.Label>
                <Col xs='8' className='fv-row'>
                  <Form.Control
                    type='number'
                    name='subnet_id'
                    defaultValue={formInput.subnet_id}
                    onChange={handleChangeInput}
                    isInvalid={!!errors.subnet_id}
                    className={!errors.subnet_id && `form-control-solid`}
                    placeholder='number'
                  />
                  <Form.Control.Feedback type='invalid'>{errors.subnet_id}</Form.Control.Feedback>

                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column xs='3' className='required form-label fw-bold fs-6 text-lg-end'>
                  Subnet
                </Form.Label>
                <Col xs='8' className='fv-row'>
                  <Form.Control
                    type='text'
                    name='subnet'
                    defaultValue={formInput.subnet}
                    onChange={handleChangeInput}
                    isInvalid={!!errors.subnet}
                    className={!errors.subnet && `form-control-solid`}
                    placeholder='0.0.0.0/0'
                  />
                  <Form.Control.Feedback type='invalid'>{errors.subnet}</Form.Control.Feedback>

                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column xs='3' className='required form-label fw-bold fs-6 text-lg-end'>
                  IP Address
                </Form.Label>
                <Col xs='8' className='fv-row'>
                  <Form.Control
                    type='text'
                    name='ip'
                    defaultValue={formInput.ip}
                    onChange={handleChangeInput}
                    isInvalid={!!errors.ip}
                    className={!errors.ip && `form-control-solid`}
                    placeholder='0.0.0.0/0'
                  />
                  <Form.Control.Feedback type='invalid'>{errors.ip}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column xs='3' className='required form-label fw-bold fs-6 text-lg-end'>
                  Gateway
                </Form.Label>
                <Col xs='8' className='fv-row'>
                  <Form.Control
                    type='text'
                    name='gateway'
                    defaultValue={formInput.gateway}
                    onChange={handleChangeInput}
                    isInvalid={!!errors.gateway}
                    className={!errors.gateway && `form-control-solid`}
                    placeholder='0.0.0.0'
                  />
                  <Form.Control.Feedback type='invalid'>{errors.gateway}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </> : <>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column xxl='2' lg='3' className='form-label fw-bold fs-6 text-lg-end'>
                  Username
                </Form.Label>
                <Col xxl='6' lg='5' className='fv-row'>
                  <Form.Control
                    type='text'
                    name='mac'
                    defaultValue={formInput.mac}
                    onChange={handleChangeInput}
                    isInvalid={!!errors.mac}
                    className={!errors.mac && `form-control-solid`}
                    placeholder=''
                  />
                  <Form.Control.Feedback type='invalid'>{errors.mac}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column xxl='2' lg='3' className='form-label fw-bold fs-6 text-lg-end'>
                  Password
                </Form.Label>
                <Col xxl='6' lg='5' className='fv-row'>
                  <Form.Control
                    type='text'
                    name='mac'
                    defaultValue={formInput.mac}
                    onChange={handleChangeInput}
                    isInvalid={!!errors.mac}
                    className={!errors.mac && `form-control-solid`}
                    placeholder=''
                  />
                  <Form.Control.Feedback type='invalid'>{errors.mac}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column xs='3' className='form-label fw-bold fs-6 text-lg-end'>
                  Addressing
                </Form.Label>
                <Col xs='8' className='fv-row'>
                  <Form.Select
                    name='state'
                    onChange={handleChangeInput}
                    isInvalid={!!errors.state}
                    className={!errors.state && `form-select-solid`}
                  >
                    <option value=''>Please select ...</option>
                    {Object.entries(routerList).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>{errors.state}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column xs='3' className='form-label fw-bold fs-6 text-lg-end'>
                  Address Pool
                </Form.Label>
                <Col xs='8' className='fv-row'>
                  <Form.Select
                    name='state'
                    onChange={handleChangeInput}
                    isInvalid={!!errors.state}
                    className={!errors.state && `form-select-solid`}
                  >
                    <option value=''>Please select ...</option>
                    {Object.entries(routerList).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>{errors.state}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </>
          }
          <div className='card-footer d-flex justify-content-end py-6'>
            <button
              type='button'
              className='btn btn-primary me-1'
              disabled={
                isSubmiting
              }
              onClick={handleSubsmit}
            >
              {!isSubmiting && ('Update')}
              {isSubmiting && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

        </Modal.Body>
      </Modal>
      <NekworkModal
        showModalNetwork={showModalNetwork}
        handleModelCloseNekwork={handleModelCloseNekwork}
        networks={networks}
      />
    </div>
  )
}
export default Subscriptions
import { useContext, useEffect, useRef, useState } from "react"
import ApexCharts from 'apexcharts'
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils"
import { DataContext } from "../../../../reducer/GlobalState"
import axios from "axios"

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aga', 'Set', 'Oct', 'Dec']
const date = new Date()
const count_month = date.getMonth() + 1

const show_month = months.slice(0, count_month)



export const ChartInvoices: React.FC<{ className: string }> = ({ className }) => {

  const { dispatch } = useContext(DataContext)
  const chartRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [summary, setSummary] = useState(0)
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    setLoading(true)
    let run = false
    axios
      .get(`/invoice/search-invoice-income-chart`)
      .then((resp) => {
        if (!run) {
          setSummary(resp.data.grand_total)
          setLoading(false)
          const chart = new ApexCharts(chartRef.current, chartOption(resp.data))
          chart.render()
        }
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
    //Refresh 5min per time
    const delayDebounceFn = setTimeout(() => {
      setRefresh(x => x + 1)
    }, 300000)
    return () => {
      run = true
      clearTimeout(delayDebounceFn)
    }
  }, [dispatch, refresh])


  return (
    // <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>
    <div className={`${className} mb-5 h-400px position-relative`}>
      {loading ?
        <div className='position-absolute top-50 start-50 translate-middle'>
          <span className={`spinner-border text-primary`}></span>
        </div>
        :
        <div className='card-body p-0'>
          <div className='flex-grow-1 card-p pb-0'>
            <div className='d-flex flex-stack'>
              <div className='me-2'>
                <h3 className='fw-bolder text-dark'>Income</h3>
              </div>
              <div className={`fw-bolder fs-3 text-`}>${summary}</div>
            </div>
          </div>
          <div ref={chartRef}></div>
        </div>
      }
    </div>
  )
}

const chartOption = ({ data_total }: any) => {
  const baseColor = getCSSVariableValue('--bs-dark')
  const baselightColor = getCSSVariableValue('--bs-light-dark')
  const labelColor = getCSSVariableValue('--bs-gray-500')
  return {
    series: [
      {
        name: 'Total',
        data: data_total,
      },

    ],
    chart: {
      type: 'area',
      height: '275px',
      zoom: {
        enabled: false,
      },
      // sparkline: {
      //   enabled: true,
      // },
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: show_month,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      // crosshairs: {
      //   show: false,
      //   position: 'front',
      //   stroke: {
      //     // color: strokeColor,
      //     width: 1,
      //     dashArray: 3,
      //   },
      // },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },
    stroke: {
      colors: [baseColor],
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val: any) {
          return '$' + val
        },
      },
    },
    colors: [baseColor],
    markers: {
      colors: [baselightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
    grid: {
      padding: {
        top: 10,
      },
      borderColor: baselightColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
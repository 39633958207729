import React from 'react'
// import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
// import {KTSVG} from '../../.././_metronic/helpers'

const SubscriptionsItem = ({
  userCan,
  servicetype,
  showId,
  i,
  num_page,
  handleAddNetwork,
  handleNekworokDetail
}) => {
  return (
    <>
      {(i > showId - 1 && (i < showId + num_page)) &&
        <tr>
          <td>{servicetype.id}</td>
          <td>
            {/* <Link className='text-dark text-hover-primary' to={`/customer/${servicetype.CustomerID}/${servicetype.customer_agent_id}/detail/subscription/${servicetype.id}/detail`}> */}
              {servicetype.SubscriptionName}
            {/* </Link> */}
          </td>
          <td>{servicetype.customer}</td>
          <td>{servicetype.tariff}</td>
          <td>
            <span
              style={{
                backgroundColor: `${servicetype.serviceStatusBackColor}`, //#009ef7
                fontSize: '0.85rem',
                fontWeight: '600',
                padding: '0.4em 0.75em',
                borderRadius: '0.5rem',
                color: `white`, //#f1faff
                lineHeight: 1,
                display: 'inline-block',
              }}
            >
              {servicetype.serviceStatus}
            </span>
          </td>
          {!servicetype.network ?
            <td>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
                onClick={() => handleAddNetwork(servicetype.id)}
                title='Add Network'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr017.svg' className='svg-icon-3' />
              </button>
            </td>
            :
            <td>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
                onClick={() => handleNekworokDetail(servicetype.id)}
                title='View'
              >
                <i className="fa fa-eye"></i>
              </button>
            </td>
          }
        </tr>
      }
    </>
  )
}

export default SubscriptionsItem
import { useContext, useEffect, useRef, useState } from "react"
import ApexCharts from 'apexcharts'
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils"
import { DataContext } from "../../../../reducer/GlobalState"
import axios from "axios"


export const ChartSubscription: React.FC<{ className: string }> = ({ className }) => {

  const { dispatch } = useContext(DataContext)
  const chartRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    setLoading(true)
    let run = false
    axios
      .get(`/subscription-chart`,)
      .then((resp) => {
        if (!run) {
          if (resp.data.status === 200) {
            setLoading(false)
            const chart = new ApexCharts(chartRef.current, chartOption(resp.data))
            chart.render()
            // const delayDebounceFn = setTimeout(() => {
            //   if (chart) {
            //   }
            // }, 500)
            // return () => clearTimeout(delayDebounceFn)
          }
        }
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
    //Refresh 5min per time
    const delayDebounceFn = setTimeout(() => {
      setRefresh(x => x + 1)
    }, 300000)
    return () => {
      run = true
      clearTimeout(delayDebounceFn)
    }
  }, [dispatch, refresh])

  return (
    // <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>
    <div className={`${className} mb-5 h-400px position-relative`}>
      {loading ?
        <div className='position-absolute top-50 start-50 translate-middle'>
          <span className={`spinner-border text-primary`}></span>
        </div>
        :
        <div className='card-body p-0'>
          <div className='flex-grow-1 card-p pb-0'>
            <div className='d-flex flex-stack'>
              <div className='me-2'>
                <h3 className='fw-bolder text-dark'>Subscription</h3>
              </div>
            </div>
          </div>
          <div ref={chartRef}></div>
        </div>
      }

    </div>
  )
}

const chartOption = ({ count_active, count_closed, data_month }: any) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-danger')
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  return {
    series: [
      {
        name: 'Active',
        data: count_active === 0 ? [0] : count_active,
      },
      {
        name: 'Close',
        data: count_closed === 0 ? [0] : count_closed,
      },
    ],
    chart: {
      type: 'bar',
      height: '300px',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
        borderRadius: 5,
      },
    },
    // legend: {
    //   show: false,
    // },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data_month === 0 ? [0] : data_month,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },

    colors: [baseColor, secondaryColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
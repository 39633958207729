import { Col, Form, Modal, Row } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../../../_metronic/helpers'

const NekworkModal = ({
  showModalNetwork,
  handleModelCloseNekwork,
  networks
}) => {

  // console.log(networks)



  return (
    <Modal show={showModalNetwork} scrollable={true} onHide={handleModelCloseNekwork} size='xl'>
      <Modal.Header>
        <Modal.Title>Network Detail</Modal.Title>
        <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleModelCloseNekwork}>
          <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='p-2 mb-2'>
          <Form.Group as={Row} className='mb-4'>
            <Col xs={3}>
              POP
            </Col>
            <Col xs={1}>:</Col>
            <Col>
              {networks.POPName}
            </Col>
          </Form.Group>
          <Form.Group className='mb-4'>
            <Row>
              <Col xs={3}>
                Device
              </Col>
              <Col xs={1}>:</Col>
              <Col className=''>
                {networks.device_name}
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className='mb-4'>
            <Row>
              <Col xs={3}>
                VLAN
              </Col>
              <Col xs={1}>:</Col>
              <Col className=''>
                {networks.vlan}
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className='mb-4'>
            <Row>
              <Col xs={3}>
                Connection Type
              </Col>
              <Col xs={1}>:</Col>
              <Col className=''>
                {networks.connection_type}
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className='mb-4'>
            <Row>
              <Col xs={3}>
                Addressing
              </Col>
              <Col xs={1}>:</Col>
              <Col className=''>
                {networks.addressing}
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className='mb-4'>
            <Row>
              <Col xs={3}>
                Subnet
              </Col>
              <Col xs={1}>:</Col>
              <Col className=''>
                {networks.subnet}
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className='mb-4'>
            <Row>
              <Col xs={3}>
                IP Address
              </Col>
              <Col xs={1}>:</Col>
              <Col className=''>
                {networks.ip_address}
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className=''>
            <Row>
              <Col xs={3}>
                Gate Way
              </Col>
              <Col xs={1}>:</Col>
              <Col className=''>
                {networks.gateway}
              </Col>
            </Row>
          </Form.Group>
        </div>

      </Modal.Body>
    </Modal>
  )
}

export default NekworkModal
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../_metronic/helpers'
import { DataContext } from '../../../reducer/GlobalState'
import { CalenderDropdown } from './custom/CalenderDropdown';

type Props = {
  color: string
  title: string
  subtitle: string
  icon: string
  status?: number
  api: string
  className: string
  money?: boolean
  url: string
}


export const DashboardUnpaidCount: React.FC<Props> = ({
  color,
  title,
  subtitle,
  icon,
  status,
  api,
  className,
  money,
  url
}) => {
  const baseColor = getCSSVariableValue(`--bs-${color}`)
  const lightColor = getCSSVariableValue(`--bs-light-${color}`)
  const { dispatch } = useContext(DataContext)
  const [loading, setLoading] = useState(false)

  const initState = {
    DateStart: '',
    DateEnd: '',
  }

  const [formInput, setFormInput] = useState(initState)
  const selectionRange = {
    startDate: null,
    endDate: null,
    key: 'data',
  }
  const [dateRange, setDateRange] = useState(selectionRange)

  const [counts, setCounts] = useState([])

  useEffect(() => {
    setLoading(true)
    let run = false
    axios
      .post(`${api}/${status}`, formInput)
      .then((resp) => {
        if (!run) {
          setCounts(resp.data.count)
          setLoading(false)
        }
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })

    return () => { run = true }

  }, [dispatch, formInput, status, api])


  return (
    <div className={`${className} mb-5`}>
      <div className={`card-header align-items-start border-0 mt-3`}>
        <h3 className='card-title align-items-start flex-column'>
          {loading ?
            <span className={`spinner-border text-${color}`}></span>
            :
            <span className={`fw-bolder mb-2 fs-1 text-${color}`}>
              {counts || 0} {money && '$'}
            </span>
          }
          <span className='text-muted fw-bold fs-7'>{subtitle}</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className={`btn btn-sm btn-icon btn-color-${color} btn-active-light-${color} border-0 me-n3`}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-2' />
          </button>
          <CalenderDropdown
            formInput={formInput}
            setFormInput={setFormInput}
            dateRange={dateRange}
            setDateRange={setDateRange}
            color={color} />
        </div>
      </div>

      <div className={`rounded-bottom position-relative`}
        style={{
          backgroundImage: `linear-gradient(15deg, ${baseColor}, ${lightColor})`
        }}>
        <div className='p-3'>
          <KTSVG path={icon} className={`svg-icon-3x svg-icon-${color} position-absolute top-0 start-75`} />
          <Link to={url} className='fw-bolder text-white'>
            {title}
          </Link>
        </div>
      </div>

    </div>
  )
}
